import IQuizResults from '../../interfaces/IQuizResults';
import QuizResult from '../../components/quiz/result/workplace-individual/QuizResult';
import image from '../../assets/images/report/superpowers.png';

export class WorkplaceIndividual implements IQuizResults {
  getResultComponent() {
    return QuizResult;
  }

  getResultPageProps({ score = 50, redirectUrl }: any) {
    return {
      subtitle: '',
      title: `Congratulations!`,
      description: `You have successfully completed your assessment. Complete the form to the right and you will receive your report right away!`,
      bulletPoints: [],
      color: 'var(--color-brand-main-900)',
      redirectUrl,
      image,
    };
  }
}
